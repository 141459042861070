export enum OfferType {
  Buy = "Buy",
  Sell = "Sell",
}

export enum OrderStatus {
  AwaitingPayment = "AwaitingPayment",
  PaymentConfirmed = "PaymentConfirmed",
  Complete = "Complete",
  Cancelled = "Cancelled",
  Dispute = "Dispute",
  OnModeration = "OnModeration",
}

export enum OrderAction {
  ConfirmPayment = "ConfirmPayment",
  CreateReview = "CreateReview",
  OpenDispute = "OpenDispute",
  ModeratorTakeOrder = "ModeratorTakeOrder",
  ModeratorComplete = "ModeratorComplete",
  ModeratorCancel = "ModeratorCancel",
}
